import React,{useState, useCallback, useEffect} from 'react';
import './Header.scss'
const Header = (props) => {
    const [y, setY] = useState(window.scrollY);
    const [logo, setLogo] = useState('/assets/1.Logos/svg-logo-la-serena-blanco.svg')
    const [scrollMenu, setScroll] = useState('')
    const [ocultar, setOcultar] = useState('ocultar')
    const [mostrar, setMostrar] = useState('mostrar')
    const [open, setOpen] = useState('');
    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
            if(window.scrollY<200){
                setScroll('')
                setLogo('assets/1.Logos/svg-logo-la-serena-blanco.svg')
                  
                  
            }
          } else if (y < window.scrollY) {
            if(window.scrollY > 200){
                setScroll('back_menu')
                if (window.innerWidth >= 1024) {
                    setLogo('assets/2.iconos/svg_hojas.svg')
                }   
            }
          }
          setY(window.scrollY);
        },
        [y]
    );
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    const handleMenu = () =>{
            setOpen(open === 'opened menu-open' ? '' : 'opened menu-open')
            setLogo(open === 'opened menu-open' ? '/assets/1.Logos/svg-logo-la-serena-blanco.svg' : '/assets/1.Logos/svg-logo-la-serena-blanco-morado.svg')
    }
    const handlePrototipos = () =>{
        handleMenu()
        props.setModalP(true)
    }
    return ( 
        <>
        <div className={`contenedor_header ${scrollMenu} ${ocultar}`}>
            <div className='button_descarga'>
                <a onClick={()=>{props.setModal(true)}}>DESCARGAR BROCHURE</a>
            </div>
            <div className='menu_logo'>
                <a href="/"><img src={logo} alt="logo" /></a>
            </div>
            <div className='menu_telefono'>
                <a target={'_blank'} href="tel:+524151055456">T. (415) 105 5456</a>
                <div className='iconos_social'>
                    <a target={'_blank'} href="https://www.facebook.com/laserenaresidencial/"><img src="/assets/2.iconos/svg-icon-facebook.svg" alt="" /></a>
                    <a target={'_blank'} href="https://instagram.com/laserena_residencial?igshid=YmMyMTA2M2Y="><img src="/assets/2.iconos/svg-icon-instagram.svg" alt="" /></a>
                </div>
            </div>
        </div>
        <header className={`${scrollMenu} ${open} ${mostrar}`}>
            <div className='icon_menu'>
                <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p>
            </div>
            <div className='menu_logo'>
                <a href="/"><img src={logo} alt="logo" /></a>
            </div>
            <nav className='menu'>
                    <ul>
                        <li><a href="#concepto" onClick={()=> handleMenu()}>CONCEPTO</a></li>
                        <li><a href="#amenidades" onClick={()=> handleMenu()}>AMENIDADES</a></li>
                        <li><a href="#masterplan" onClick={()=> handleMenu()}>MASTER PLAN</a></li>
                        <li><a onClick={()=> handlePrototipos()}>PROTOTIPOS</a></li>
                        <li><a href="#sanmiguel" onClick={()=> handleMenu()}>SAN MIGUEL</a></li>
                        <li><a href="#ubicacion" onClick={()=> handleMenu()}>UBICACIÓN</a></li>
                    </ul>
                    <a className='descarga' onClick={()=>{props.setModal(true)}} >DESCARGAR BROCHURE</a>
                    <div className='iconos_social'>
                        <a target={'_blank'} href="https://www.facebook.com/laserenaresidencial/"><img src="/assets/2.iconos/svg-icon-facebook.svg" alt="" /></a>
                        <a target={'_blank'} href="https://instagram.com/laserena_residencial?igshid=YmMyMTA2M2Y="><img src="/assets/2.iconos/svg-icon-instagram.svg" alt="" /></a>
                    </div>
            </nav>
        </header>
        </>
     );
}
 
export default Header;
