import React, { useState } from "react";
import "./Ubicacion.scss";
import { MapBox, MapBoxContext } from "@renderincmx/spectrum-ui";
import { mapPois, DEFAULT_MAP_CENTER } from "./locationPois";

const marker = {
  iconUrl: "/assets/mark.png",
  iconSize: [125, 65],
  iconAnchor: [12, 45],
  className: "mark",
};
const Ubicacion = () => {
  return (
    <div id="ubicacion" className="contenedor_ubicacion">
      <div className="contenedor_ubicacion_card">
        <MapBox className="mapbox" pois={mapPois} routeColor="rgb(255, 0, 0)">
          <div className="left">
            <div className="card">
              {/* <img src="/assets/4.Imagenes/img_mapa_la_serena.jpg" alt="" /> */}
              <MapBox.Map
                mapThemeUrl="https://api.mapbox.com/styles/v1/stephaniefg/clr9yabxu004j01pdd9yncsos/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RlcGhhbmllZmciLCJhIjoiY2xqZzNsZWY5MDV6YzNxbGp2dnZtaTZrMiJ9.c2K26WPWBL5RBTuT-Lqoig"
                zoom={15}
                center={DEFAULT_MAP_CENTER}
                markerIcon={marker}
                className="mapa"
              />
            </div>
          </div>
          <div className="right">
            <div className="titulo_ubicacion">
              <img
                className="wow animate__animated animate__fadeInDown"
                src="/assets/3.Titulos/ubicacion.svg"
                alt=""
              />
            </div>
            <div className="direccion_ubicacion">
              <p>
                Carretera San Miguel - Celaya 41, Fracc. Villa de Los Frailes,
                37790 San Miguel de Allende, Gto.
              </p>
            </div>
            <div className="iconos_ubicacion">
              <a target={"_blank"} href="https://goo.gl/maps/qPZQqBDrf1igHQJp8">
                <img src="/assets/1.Logos/svg-icon-maps.svg" alt="" />
              </a>
              <a
                target={"_blank"}
                href="https://www.waze.com/en/live-map/directions/mx/gto./san-miguel-de-allende/la-serena-residencial?place=ChIJQwE8J9BRK4QRol99OVL8x-g&utm_campaign=waze_website&utm_medium=website_menu&utm_source=waze_website"
              >
                <img src="/assets/1.Logos/svg-icon-waze.svg" alt="" />
              </a>
            </div>
            <div className="lista_ubicacion">
              <>
                <div className="row_ubicacion">
                  <div className="row_ubicacion_styles">
                    <MapBoxContext.Consumer>
                      {({ pois, handlePoiClick }) =>
                        pois.map((poi, index) => (
                          <div
                            className="styles"
                            key={poi.id}
                            onClick={() => handlePoiClick(poi.id)}
                          >
                            <p>{index + 1}</p>
                            <p className="name">{poi.name}</p>
                          </div>
                        ))
                      }
                    </MapBoxContext.Consumer>
                  </div>
                </div>
              </>
            </div>
          </div>
        </MapBox>
      </div>
    </div>
  );
};

export default Ubicacion;
