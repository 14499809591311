import React from 'react';
import './TempPrototipos.scss'
const TempPrototipos = (props) => {
    const {children} = props
    return ( 

        <>
         {children} 
        </>

     );
}
 
export default TempPrototipos;