import React from 'react';
import './Prototipos.scss'
const Prototipos = (props) => {
    return ( 
        <div className='contenedor_prototipos'>
            <div className='contenedor_grid'>
                <div className='left'>
                    <div className='card'>
                        <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/nuestros-prototipos.svg" alt="" />
                        <div className='texto'>
                            <p>La Serena es un homenaje al pintoresco pueblo mágico de San Miguel de Allende, honra su belleza en cada espacio y rincón del residencial, buscando que vivas una experiencia sin igual.</p>
                        </div>
                        <div className='button_descarga'>
                            <a onClick={()=>{props.setModal(true)}} >VER PROTOTIPOS</a>
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='imagen_right'>
                        <img src="/assets/4.Imagenes/img_prototipos_la_serena.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Prototipos;