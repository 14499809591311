import React from 'react';
import './Stiky.scss'
const Stiky = (props) => {
    return ( 
        <div className='contenedor_stiky'>
            <div className='whatsapp'>
               <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524151055456&text="> <img src="assets/2.iconos/svg-icon-whatsapp.svg" alt="whatsapp"/></a>
            </div>
            <div className='brochure'>
                <img onClick={()=>{props.setModal(true)}} src="assets/2.iconos/svg-icon-brochure.svg" alt="brochure" />
            </div>
        </div>
     );
}

export default Stiky;
