import React,{useState} from 'react';
import './Brochure.scss'
import * as yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
const Brochure = (props) => {
    const [nombre, colocarNombre] = useState("")
    const [tel, colocarTel] = useState("")
    const [email, colocarEmail] = useState("")
    const [recaptcha, setreCaptcha] = useState("")
    const [errors, setErrors] = useState([])
    const [reqname, setRequerido] = useState("")
    const [reqtel, setReqtel] = useState("")

    const validationSchema = yup.object({
        nombre: yup.string().required("El nombre es requerido"),
        tel: yup.string()
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "El numero de teléfono no es válido"
        ).min(10, "Mínimo 10 dígitos")
        .max(10, "Máximo 10 dígitos").required("El teléfono es requerido"),
        email: yup.string().email(),
        recaptcha: yup.string().required("Resuelva el recaptcha")
    })
    const submit = async (e) => {
        const values = {
          nombre: nombre,
          tel: tel,
          email: email,
          recaptcha: recaptcha
        }

        const isFormValid = await validationSchema.isValid(values);

        if (isFormValid) {
                const obj = {
                    nombre: nombre,
                    telefono: tel,
                    email: email,
                    recaptcha: recaptcha
                }
                   
                  const res = await axios.post('https://laserena.mx/php/index.php',obj)
                    console.log(res.data)
                    if(res.data.success){
                        swal("Enviado", "Los datos han sido enviados correctamente", "success");
                        colocarNombre("");
                        colocarEmail("");
                        colocarTel("");
                        
                        var link = document.createElement('a');
                        link.href = '/assets/Brochure/Brochure_digital.pdf'
                        link.target ='_blank'
                        link.download = 'Brochure.pdf';
                        link.dispatchEvent(new MouseEvent('click'));
                    }else{
                        if(res.data.error){
                            swal("Error", "Ocurrio un problema al enviar el correo", "error");
                        }
                    }
            
        } else {
            validationSchema.validate(values, { abortEarly: false }).catch((error) => {
              const errors = error.inner.map((err) => {
                return err.errors[0]
              })
              setErrors(errors)
            })
        }
        
    }
    const onChangeName = (evt) => {
        colocarNombre(evt.currentTarget.value)
        setRequerido("")
    }
    const onChangeTel = (evt) => {
        colocarTel(evt.currentTarget.value)
        setReqtel("")
    }
    const onChangeEmail = (evt) => {
        colocarEmail(evt.currentTarget.value)
    }
    const onChange = (val) => {
        setreCaptcha(val);
    }
    return ( 
        <div className='contenedor_brochure'>
            <p className='closed' onClick={()=>{props.setModal(false)}}>X</p>
            <div className='modal'>
                <img src="/assets/1.Logos/svg-logo-la-serena-blanco.svg" alt="" />
                <p>Déjenos tus datos y descarga nuestro brochure</p>
                <div className='errores'>
                        <ul>
                            { 
                            errors.map((error)=>{
                                return (
                                    <li>{error}</li>
                                )
                            })
                            }
                        </ul>
                </div>
                <form>
                    <div>
                        <input type="text" name='nombre' placeholder='Nombre' value={nombre} onChange={onChangeName}/>
                        <input type="text" name='telefono' placeholder='Teléfono'value={tel} onChange={onChangeTel} />
                    </div>
                    <div>
                        <input type="email" name='email' placeholder='Correo electrónico' value={email} onChange={onChangeEmail}/>
                    </div>
                    <div>
                        <ReCAPTCHA
                            sitekey="6LcF_28fAAAAACMsaUPGjQs_zeZ42n_N0_hZImb7"
                            onChange={onChange}
                        />
                        <input type="button" value={'DESCARGAR'} onClick={()=>submit()}/>
                    </div>
                    <p>*Al descargar brochure acepta nuestros términos de privacidad.</p>
                </form>
                
            </div>
        </div>
     );
}
 
export default Brochure;