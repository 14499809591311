import React,{useState} from 'react';
import './Menu.scss'

const Menu = (props) => {
    const [ocultar, setOcultar] = useState('ocultar')
    const [mostrar, setMostrar] = useState('mostrar')
    return ( 
        <>
        <div className={`contenedor_menu ${ocultar}`}>
            <div className='contenedor_menu_lista'>
                <ul>
                    <li><span className='span-left'></span><a href="#concepto">CONCEPTO</a><span className='span-right'></span></li>
                    <li><span className='span-left'></span><a href="#amenidades">AMENIDADES</a><span className='span-right'></span></li>
                    <li><span className='span-left'></span><a href="#masterplan">MASTER PLAN</a><span className='span-right'></span></li>
                    <li><span className='span-left'></span><a onClick={()=>{props.setModal(true)}}>PROTOTIPOS</a><span className='span-right'></span></li>
                    <li><span className='span-left'></span><a href="#sanmiguel">SAN MIGUEL</a><span className='span-right'></span></li>
                    <li><span className='span-left'></span><a href="#ubicacion">UBICACIÓN</a><span className='span-right'></span></li>
                </ul>
            </div>
        </div>
        <div className={`menu_movil ${mostrar}`}>
            <a className='icon_telefono'target={'_blank'} href="tel:+524151055456"><img style={{
                height: 30
            }} src="/assets/2.iconos/svg-icon-tel.svg" alt="" /></a>
            <ul>
                <li onClick={()=>{props.setModal(true)}}><span className='span-left'></span>VER PROTOTIPOS<span className='span-right'></span></li>
            </ul>
        </div>
        </>
     );
}
 
export default Menu;
