import React from 'react';
import './Footer.scss'
const Footer = () => {
    return ( 
        <div className='contenedor_footer'>
            <img className='logo_footer' src="/assets/1.Logos/svg-logo-la-serena-footer.svg" alt="" />
            <p>© 2022 LA SERENA® Todos los derechos reservados | <a href="/">Política de privacidad</a></p>
            <div><p>Diseñado por:</p><a target={'_blank'} href="https://renderinc.mx"><img src="/assets/1.Logos/logo-renderinc.svg" alt="" /></a></div>
        </div>
     );
}
 
export default Footer;