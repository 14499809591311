import React from 'react';
import './Principal.scss';
import { useParallax } from 'react-scroll-parallax';
const Principal = () => {
    const down = useParallax({
        speed: -15  
    });
    return ( 
        <div className='contenedor_principal'>
            {/* <ParallaxBanner
                layers={[
                    { image: '/assets/4.Imagenes/img_hero_la_serena.jpg', speed: -20 },
                ]}
                className="img_parallax"
            >
                <img className='contenedor_principal_titulo' src="/assets/3.Titulos/un-homenajo-a-san-miguel.svg" alt="" />
            </ParallaxBanner> */}
            <video className='fondo_video' autoPlay muted="muted" loop>
                <source src="/assets/Loop_serena_portada.mp4" type="video/mp4"/>
            </video>
            <img ref={down.ref} className='contenedor_principal_titulo' src="/assets/3.Titulos/un-homenajo-a-san-miguel.svg" alt="" />
            {/* <img src="/assets/4.Imagenes/img_hero_la_serena.jpg" alt="" /> */}
        </div>
     );
}
 
export default Principal;