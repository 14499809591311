import React from 'react';
import './Concepto.scss'
const Concepto = (props) => {
    return ( 
        <div id="concepto" className='contenedor_concepto'>
            <div className='contenedor_concepto contenedor_grid'>
                <div className='left'>
                    {/* <img className='icon_play' src="/assets/2.iconos/boton-play.svg" alt="" /> */}
                    {/* <img className='fondo_video' src="https://via.placeholder.com/960x1080/" alt="" /> */}
                    <video className='fondo_video' autoPlay muted="muted" loop>
                        <source src="/assets/loop_serena.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className='right'>
                    <div className='card'>
                        <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/svg-logo-la-serena-blanco-2.svg" alt="" />
                        <div className='texto'>
                            <p>No hay mejor lugar para generar historias que San Miguel de Allende, aquí los días están llenos de colores, en el aire se percibe la fragancia de las flores que adornan sus callejones, los cuales nos invitan a conocer su pasado legendario, un pasado que se deja ver a través de los muros, calles empedradas y sus plazoletas coloniales.</p>
                        </div>
                        <div className='button_descarga'>
                            <a onClick={()=>{props.setModal(true)}} >VER PROTOTIPOS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Concepto;