import React from 'react';
import './Galeria.scss'
const Galeria = () => {
    return ( 
        <div className='contenedor_galeria'>
            <div className='titulo_galeria'><h1>#LaSerenaResidencial</h1></div>
            <div className='grid_galeria'>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_1.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_2.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_3.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_4.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_5.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_6.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_7.jpg" alt="" /></a>  </div>
                <div className='wow animate__animated animate__fadeIn'> <a href='https://www.instagram.com/laserena_residencial/' target={'_blank'} rel="noreferrer"><img src="/assets/8.Cuadricula/inst_8.jpg" alt="" /></a>  </div>
            </div>
        </div>
     );
}
 
export default Galeria;