import React from 'react';
import './Amenidades.scss'
import Slider from '../Slider/Slider';
import { SwiperSlide } from "swiper/react";
const Amenidades = (props) => {
    return ( 
        <div id='amenidades' className='contenedor_amenidades'>
            <div className='contenedor_grid'>
                <div className='left'>
                <Slider nav={true} pag={true}>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/1.albercas_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/2.spa_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/3.gimnasio_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/4.barberia_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/5.extensas_areas_verdes_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/6.mirador_a_la_presa_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/7.canchas_de_tenis_y_padel_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/8.jacuzzi_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/7.Amenidades/thumbs/9.restaurante_la_serena.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                </Slider>
                </div>
                <div className='right'>
                    <div className='card'>
                        <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/nuestras-amenidades.svg" alt="" />
                        <div className='texto'>
                            <p>No hay mejor lugar para generar historias que San Miguel de Allende, aquí los días están llenos de colores, en el aire se percibe la fragancia de las flores que adornan sus callejones, los cuales nos invitan a conocer su pasado legendario, un pasado que se deja ver a través de los muros, calles empedradas y sus plazoletas coloniales.</p>
                        </div>
                        <div className='button_descarga'>
                            <a onClick={()=>{props.setModal(true)}} >VER PROTOTIPOS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Amenidades;