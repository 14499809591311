import React from 'react';
import './Descripcion.scss'
import { useParallax } from 'react-scroll-parallax';
const Descipcion = (props) => {

    const down = useParallax({
        speed: -15  
    });
    const up = useParallax({
        speed: 5
    })
    return ( 
        <div className='contenedor_descripcion'>
            <div  className='contenedor_descripcion_card'>
                <div className='left'>
                    <div className='card'>
                        <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/el-alma-de-una-ciudad-colonial.svg" alt="" />
                        <div className='texto'>
                            <p>La Serena Residencial brinda espacios contemporáneos inigualables, inspirados en el encanto, autenticidad y naturaleza de este pueblo mágico, estos espacios te invitan a vivir y disfrutar de este estilo de vida, lleno de romanticismo, confort e historia, en un entorno seguro para disfrutar de momentos únicos, que recordarás para siempre.</p>
                        </div>
                        <div className='button_descarga'>
                            <a onClick={()=>{props.setModal(true)}}>VER PROTOTIPOS</a>
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <img className='' ref={down.ref}  src="/assets/4.Imagenes/img_mujer_la_serena.jpg" alt="" />
                    <img ref={up.ref} src="/assets/4.Imagenes/img_pareja_la_serena.jpg" alt="" />
                </div>
            </div>
        </div>
     );
}
 
export default Descipcion;