import React, { useState } from "react";
// Import Swiper React components
import { Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation]);
const amenidades = [
  "3 Albercas",
  "Spa",
  "Gimnasio",
  "Barberia",
  "Áreas verdes",
  "Mirador a la presa",
  "Canchas de tenis y padel",
  "Vapor",
  "Restaurante",
];
const Slider = (props) => {
  const { children } = props;
  const [amenidad, setAmenidad] = useState(amenidades[0]);

  const handleChange = (swiper) => {
    // console.log('swiper',swiper)
    setAmenidad(amenidades[swiper.activeIndex]);
  };
  return (
    <>
      <Swiper
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          type: "fraction",
        }}
        navigation={props.nav}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={(swiper) => {
          handleChange(swiper);
        }}
        className="mySwiper"
      >
        {children}
        <p className="pagination_name">- {amenidad}</p>
      </Swiper>
    </>
  );
};

export default Slider;
