export const DEFAULT_MAP_CENTER = [20.89122442949524, -100.76085557516939];
export const mapPois = [
  {
    id: "centro-historico",
    name: "Centro histórico",
    position: [20.913733753801434, -100.74209590859894],
    routeCoordinates: [
      [-100.742131, 20.913732, 1926.0],
      [-100.742074, 20.912746, 1926.0],
      [-100.742888, 20.912695, 1926.0],
      [-100.743635, 20.912696, 1926.0],
      [-100.744593, 20.912642, 1922.3],
      [-100.74513, 20.91264, 1918.5],
      [-100.746182, 20.912615, 1908.0],
      [-100.746258, 20.912025, 1902.1],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3435.1,
      duration: 275.8,
    },
  },
  {
    id: "el-mirador",
    name: "El mirador",
    position: [20.90957643064466, -100.73869103201557],
    routeCoordinates: [
      [-100.738823, 20.909554, 1999.0],
      [-100.738824, 20.909559, 1999.0],
      [-100.738851, 20.909763, 1999.0],
      [-100.738856, 20.909976, 1999.0],
      [-100.738821, 20.910464, 1997.0],
      [-100.738758, 20.910956, 1994.9],
      [-100.738744, 20.911109, 1994.5],
      [-100.738765, 20.911165, 1994.2],
      [-100.738897, 20.911634, 1992.2],
      [-100.738938, 20.91174, 1991.7],
      [-100.739141, 20.912333, 1989.4],
      [-100.739201, 20.912664, 1987.5],
      [-100.739829, 20.912738, 1978.8],
      [-100.740497, 20.912793, 1965.4],
      [-100.741236, 20.912728, 1949.1],
      [-100.742074, 20.912746, 1937.8],
      [-100.742888, 20.912695, 1931.3],
      [-100.743635, 20.912696, 1926.9],
      [-100.744593, 20.912642, 1922.3],
      [-100.74513, 20.91264, 1918.5],
      [-100.746182, 20.912615, 1908.0],
      [-100.746258, 20.912025, 1902.1],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3978.2,
      duration: 346.8,
    },
  },
  {
    id: "museo",
    name: "Museo Casa Allende",
    position: [20.913799531936455, -100.74406833293787],
    routeCoordinates: [
      [-100.74459, 20.913814, 1911.0],
      [-100.74461, 20.913202, 1911.0],
      [-100.744593, 20.912642, 1911.0],
      [-100.74513, 20.91264, 1911.0],
      [-100.746182, 20.912615, 1907.2],
      [-100.746258, 20.912025, 1902.1],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3193.7,
      duration: 244.5,
    },
  },
  {
    id: "aurora",
    name: "Centro cultural la Aurora",
    position: [20.915086612106442, -100.74536650297566],
    routeCoordinates: [
      [-100.745255, 20.915088, 1910.0],
      [-100.745264, 20.915638, 1910.0],
      [-100.745255, 20.916902, 1906.2],
      [-100.746755, 20.917189, 1898.0],
      [-100.746714, 20.916968, 1897.1],
      [-100.746645, 20.916626, 1896.1],
      [-100.746537, 20.916147, 1895.6],
      [-100.746502, 20.915794, 1896.1],
      [-100.746508, 20.915588, 1896.5],
      [-100.746685, 20.914472, 1900.4],
      [-100.746694, 20.914388, 1900.4],
      [-100.746698, 20.91399, 1900.0],
      [-100.745881, 20.913972, 1901.5],
      [-100.746182, 20.912615, 1904.5],
      [-100.746258, 20.912025, 1900.4],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.5],
      [-100.750479, 20.901981, 1906.8],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.2],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.6],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.5],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3857.3,
      duration: 359.3,
    },
  },
  {
    id: "artesanías",
    name: "Mercado de Artesanías",
    position: [20.91747364308278, -100.74175558267989],
    routeCoordinates: [
      [-100.741906, 20.917618, 1911.8],
      [-100.74196, 20.917569, 1912.0],
      [-100.742262, 20.917883, 1910.8],
      [-100.74236, 20.917672, 1910.3],
      [-100.742504, 20.91722, 1910.1],
      [-100.742617, 20.91671, 1910.1],
      [-100.742714, 20.916359, 1910.8],
      [-100.74234, 20.91609, 1912.2],
      [-100.742265, 20.916006, 1912.6],
      [-100.742236, 20.91584, 1913.0],
      [-100.742246, 20.915576, 1913.8],
      [-100.741935, 20.915514, 1914.8],
      [-100.741996, 20.914532, 1917.0],
      [-100.742331, 20.914518, 1917.0],
      [-100.742325, 20.914028, 1918.4],
      [-100.742148, 20.914019, 1918.8],
      [-100.742074, 20.912746, 1925.1],
      [-100.742888, 20.912695, 1926.0],
      [-100.743635, 20.912696, 1926.0],
      [-100.744593, 20.912642, 1922.3],
      [-100.74513, 20.91264, 1918.5],
      [-100.746182, 20.912615, 1908.0],
      [-100.746258, 20.912025, 1902.1],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 4057.6,
      duration: 405.9,
    },
  },
  {
    id: "teatro",
    name: "Teatro Ángela Peralta",
    position: [20.91560892129188, -100.7450995635523],
    routeCoordinates: [
      [-100.745098, 20.91563, 1910.0],
      [-100.745264, 20.915638, 1910.0],
      [-100.745255, 20.916902, 1905.3],
      [-100.746755, 20.917189, 1898.0],
      [-100.746714, 20.916968, 1897.1],
      [-100.746645, 20.916626, 1896.1],
      [-100.746537, 20.916147, 1895.6],
      [-100.746502, 20.915794, 1896.1],
      [-100.746508, 20.915588, 1896.5],
      [-100.746685, 20.914472, 1900.4],
      [-100.746694, 20.914388, 1900.4],
      [-100.746698, 20.91399, 1900.0],
      [-100.745881, 20.913972, 1901.6],
      [-100.746182, 20.912615, 1904.5],
      [-100.746258, 20.912025, 1900.4],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3813.4,
      duration: 354.8,
    },
  },
  {
    id: "ignacio",
    name: "Centro cultural Ignacio Ramírez",
    position: [20.91500236640819, -100.74536747329732],
    routeCoordinates: [
      [-100.745253, 20.915004, 1910.0],
      [-100.745264, 20.915638, 1910.0],
      [-100.745255, 20.916902, 1906.2],
      [-100.746755, 20.917189, 1898.0],
      [-100.746714, 20.916968, 1897.1],
      [-100.746645, 20.916626, 1896.1],
      [-100.746537, 20.916147, 1895.6],
      [-100.746502, 20.915794, 1896.1],
      [-100.746508, 20.915588, 1896.5],
      [-100.746685, 20.914472, 1900.4],
      [-100.746694, 20.914388, 1900.4],
      [-100.746698, 20.91399, 1900.0],
      [-100.745881, 20.913972, 1901.5],
      [-100.746182, 20.912615, 1904.5],
      [-100.746258, 20.912025, 1900.4],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.5],
      [-100.750479, 20.901981, 1906.8],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.2],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.6],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.5],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 3866.7,
      duration: 360.2,
    },
  },
  {
    id: "golf",
    name: "Club de golf Malaquin",
    position: [20.896640860813147, -100.76422051443029],
    routeCoordinates: [
      [-100.765394, 20.895415, 1907.0],
      [-100.765877, 20.89548, 1907.0],
      [-100.766247, 20.895615, 1907.0],
      [-100.766355, 20.89546, 1907.0],
      [-100.766462, 20.895226, 1907.0],
      [-100.766709, 20.895273, 1907.0],
      [-100.766803, 20.895246, 1907.0],
      [-100.766717, 20.895201, 1907.0],
      [-100.766386, 20.895139, 1907.0],
      [-100.765342, 20.894944, 1907.0],
      [-100.76424, 20.894741, 1908.5],
      [-100.763197, 20.894548, 1911.2],
      [-100.762153, 20.894356, 1912.3],
      [-100.76127, 20.894193, 1912.8],
      [-100.761167, 20.894208, 1912.8],
      [-100.760965, 20.894218, 1913.2],
      [-100.759784, 20.894438, 1918.2],
      [-100.759404, 20.894161, 1921.2],
      [-100.759016, 20.893879, 1923.5],
      [-100.758955, 20.893834, 1923.8],
      [-100.758883, 20.893782, 1924.0],
      [-100.758509, 20.893707, 1924.7],
      [-100.757859, 20.893576, 1925.4],
      [-100.758767, 20.892837, 1927.9],
      [-100.759027, 20.892624, 1928.7],
      [-100.75923, 20.89245, 1929.3],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 1555.0,
      duration: 298.2,
    },
  },
  {
    id: "parque",
    name: "Parque de Aventura",
    position: [20.89921437511339, -100.73227544221267],
    routeCoordinates: [
      [-100.733349, 20.900059, 2075.0],
      [-100.733144, 20.900287, 2068.0],
      [-100.731808, 20.901801, 2061.0],
      [-100.731451, 20.90219, 2058.0],
      [-100.731229, 20.902335, 2056.6],
      [-100.731128, 20.902426, 2056.2],
      [-100.730868, 20.902699, 2054.6],
      [-100.730595, 20.902951, 2053.4],
      [-100.730479, 20.903056, 2053.0],
      [-100.730545, 20.903109, 2052.6],
      [-100.730719, 20.903216, 2051.8],
      [-100.730778, 20.903134, 2051.4],
      [-100.731336, 20.902513, 2050.9],
      [-100.731466, 20.902392, 2050.4],
      [-100.73161, 20.902297, 2049.9],
      [-100.731769, 20.902234, 2049.4],
      [-100.732164, 20.902118, 2047.3],
      [-100.732314, 20.902117, 2046.7],
      [-100.732464, 20.902135, 2046.1],
      [-100.733068, 20.902301, 2042.9],
      [-100.733613, 20.902474, 2040.8],
      [-100.734658, 20.902788, 2037.5],
      [-100.735016, 20.902909, 2036.8],
      [-100.735195, 20.903006, 2036.3],
      [-100.735489, 20.903199, 2036.3],
      [-100.735678, 20.903357, 2036.9],
      [-100.735921, 20.903619, 2038.1],
      [-100.736554, 20.904281, 2034.7],
      [-100.736879, 20.904631, 2029.6],
      [-100.737234, 20.904895, 2024.4],
      [-100.737477, 20.905002, 2021.2],
      [-100.737679, 20.905031, 2018.2],
      [-100.737928, 20.905044, 2015.9],
      [-100.738152, 20.905027, 2014.2],
      [-100.738316, 20.904997, 2013.5],
      [-100.738639, 20.904838, 2012.0],
      [-100.739453, 20.90429, 2004.8],
      [-100.739745, 20.904058, 2002.5],
      [-100.739881, 20.903882, 2001.0],
      [-100.739977, 20.903708, 1999.5],
      [-100.740192, 20.90319, 1996.4],
      [-100.740309, 20.903054, 1996.0],
      [-100.74046, 20.902935, 1995.5],
      [-100.740665, 20.902863, 1995.1],
      [-100.741349, 20.902675, 1995.0],
      [-100.741526, 20.902573, 1995.0],
      [-100.74163, 20.90248, 1995.0],
      [-100.741735, 20.902314, 1993.8],
      [-100.741957, 20.901728, 1988.8],
      [-100.742027, 20.9016, 1988.0],
      [-100.742121, 20.901473, 1987.1],
      [-100.742356, 20.901222, 1984.0],
      [-100.742507, 20.901062, 1982.0],
      [-100.742582, 20.900904, 1981.1],
      [-100.742615, 20.900713, 1979.5],
      [-100.742647, 20.900227, 1977.4],
      [-100.742684, 20.900044, 1977.5],
      [-100.74277, 20.899901, 1977.7],
      [-100.742859, 20.899796, 1978.0],
      [-100.743073, 20.899726, 1979.6],
      [-100.74398, 20.899637, 1982.8],
      [-100.744385, 20.899603, 1981.4],
      [-100.744833, 20.899488, 1979.7],
      [-100.745133, 20.899331, 1977.0],
      [-100.74542, 20.899133, 1974.1],
      [-100.745653, 20.898905, 1972.4],
      [-100.745786, 20.898711, 1971.0],
      [-100.746031, 20.898181, 1962.8],
      [-100.7467, 20.896732, 1937.3],
      [-100.746785, 20.896597, 1937.2],
      [-100.746978, 20.896368, 1937.9],
      [-100.747316, 20.89607, 1938.8],
      [-100.747609, 20.89594, 1939.4],
      [-100.747823, 20.895877, 1938.9],
      [-100.748269, 20.895827, 1937.0],
      [-100.748478, 20.89582, 1935.9],
      [-100.748749, 20.895857, 1934.7],
      [-100.748971, 20.895917, 1933.5],
      [-100.749197, 20.896, 1932.2],
      [-100.749495, 20.896158, 1930.1],
      [-100.750358, 20.896742, 1924.0],
      [-100.750462, 20.896858, 1923.5],
      [-100.750539, 20.896914, 1923.0],
      [-100.751552, 20.897626, 1918.4],
      [-100.752032, 20.897951, 1917.3],
      [-100.75234, 20.898161, 1916.3],
      [-100.752782, 20.898449, 1914.3],
      [-100.752964, 20.898582, 1913.5],
      [-100.753087, 20.898668, 1913.1],
      [-100.75317, 20.898565, 1912.7],
      [-100.753233, 20.89848, 1912.3],
      [-100.753496, 20.898129, 1910.9],
      [-100.753849, 20.897629, 1910.9],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 4773.6,
      duration: 352.2,
    },
  },
  {
    id: "cruz-roja",
    name: "Cruz Roja",
    position: [20.90662003104135, -100.75645577434453],
    routeCoordinates: [
      [-100.756365, 20.906563, 1887.7],
      [-100.756189, 20.906806, 1886.3],
      [-100.755775, 20.906431, 1887.7],
      [-100.755603, 20.906229, 1888.5],
      [-100.755469, 20.906037, 1889.3],
      [-100.755226, 20.905567, 1890.7],
      [-100.755151, 20.905314, 1891.1],
      [-100.755095, 20.905066, 1891.8],
      [-100.755076, 20.904808, 1892.8],
      [-100.755085, 20.904288, 1894.6],
      [-100.75514, 20.903608, 1896.0],
      [-100.755217, 20.903022, 1897.3],
      [-100.755305, 20.902054, 1899.3],
      [-100.755357, 20.901399, 1902.3],
      [-100.755367, 20.901094, 1904.2],
      [-100.755365, 20.900978, 1904.8],
      [-100.755307, 20.900711, 1906.6],
      [-100.755246, 20.900521, 1907.8],
      [-100.755195, 20.900302, 1909.2],
      [-100.75509, 20.900035, 1911.4],
      [-100.754787, 20.899625, 1913.4],
      [-100.754577, 20.899434, 1913.7],
      [-100.753972, 20.898977, 1913.5],
      [-100.753684, 20.898788, 1912.7],
      [-100.753415, 20.898599, 1912.0],
      [-100.75336, 20.89849, 1911.7],
      [-100.753379, 20.898346, 1911.5],
      [-100.753496, 20.898129, 1911.1],
      [-100.753849, 20.897629, 1911.0],
      [-100.753882, 20.89754, 1911.2],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 2212.5,
      duration: 154.5,
    },
  },
  {
    id: "hospital-mac",
    name: "Hospital Mac",
    position: [20.901773761418134, -100.72965934366017],
    routeCoordinates: [
      [-100.729767, 20.90173, 2056.5],
      [-100.730096, 20.902432, 2054.8],
      [-100.730215, 20.9026, 2054.4],
      [-100.730479, 20.903056, 2054.0],
      [-100.730545, 20.903109, 2053.9],
      [-100.730719, 20.903216, 2053.8],
      [-100.730778, 20.903134, 2053.8],
      [-100.731336, 20.902513, 2050.9],
      [-100.731466, 20.902392, 2050.4],
      [-100.73161, 20.902297, 2049.9],
      [-100.731769, 20.902234, 2049.4],
      [-100.732164, 20.902118, 2047.3],
      [-100.732314, 20.902117, 2046.7],
      [-100.732464, 20.902135, 2046.1],
      [-100.733068, 20.902301, 2042.9],
      [-100.733613, 20.902474, 2040.8],
      [-100.734658, 20.902788, 2037.5],
      [-100.735016, 20.902909, 2036.8],
      [-100.735195, 20.903006, 2036.3],
      [-100.735489, 20.903199, 2036.3],
      [-100.735678, 20.903357, 2036.9],
      [-100.735921, 20.903619, 2038.1],
      [-100.736554, 20.904281, 2034.7],
      [-100.736879, 20.904631, 2029.6],
      [-100.737234, 20.904895, 2024.4],
      [-100.737477, 20.905002, 2021.2],
      [-100.737679, 20.905031, 2018.2],
      [-100.737928, 20.905044, 2015.9],
      [-100.738152, 20.905027, 2014.2],
      [-100.738316, 20.904997, 2013.5],
      [-100.738639, 20.904838, 2012.0],
      [-100.739453, 20.90429, 2004.8],
      [-100.739745, 20.904058, 2002.5],
      [-100.739881, 20.903882, 2001.0],
      [-100.739977, 20.903708, 1999.5],
      [-100.740192, 20.90319, 1996.4],
      [-100.740309, 20.903054, 1996.0],
      [-100.74046, 20.902935, 1995.5],
      [-100.740665, 20.902863, 1995.1],
      [-100.741349, 20.902675, 1995.0],
      [-100.741526, 20.902573, 1995.0],
      [-100.74163, 20.90248, 1995.0],
      [-100.741735, 20.902314, 1993.8],
      [-100.741957, 20.901728, 1988.8],
      [-100.742027, 20.9016, 1988.0],
      [-100.742121, 20.901473, 1987.1],
      [-100.742356, 20.901222, 1984.0],
      [-100.742507, 20.901062, 1982.0],
      [-100.742582, 20.900904, 1981.1],
      [-100.742615, 20.900713, 1979.5],
      [-100.742647, 20.900227, 1977.4],
      [-100.742684, 20.900044, 1977.5],
      [-100.74277, 20.899901, 1977.7],
      [-100.742859, 20.899796, 1978.0],
      [-100.743073, 20.899726, 1979.6],
      [-100.74398, 20.899637, 1982.8],
      [-100.744385, 20.899603, 1981.4],
      [-100.744833, 20.899488, 1979.7],
      [-100.745133, 20.899331, 1977.0],
      [-100.74542, 20.899133, 1974.1],
      [-100.745653, 20.898905, 1972.4],
      [-100.745786, 20.898711, 1971.0],
      [-100.746031, 20.898181, 1962.8],
      [-100.7467, 20.896732, 1937.3],
      [-100.746785, 20.896597, 1937.2],
      [-100.746978, 20.896368, 1937.9],
      [-100.747316, 20.89607, 1938.8],
      [-100.747609, 20.89594, 1939.4],
      [-100.747823, 20.895877, 1938.9],
      [-100.748269, 20.895827, 1937.0],
      [-100.748478, 20.89582, 1935.9],
      [-100.748749, 20.895857, 1934.7],
      [-100.748971, 20.895917, 1933.5],
      [-100.749197, 20.896, 1932.2],
      [-100.749495, 20.896158, 1930.1],
      [-100.750358, 20.896742, 1924.0],
      [-100.750462, 20.896858, 1923.5],
      [-100.750539, 20.896914, 1923.0],
      [-100.751552, 20.897626, 1918.4],
      [-100.752032, 20.897951, 1917.3],
      [-100.75234, 20.898161, 1916.3],
      [-100.752782, 20.898449, 1914.3],
      [-100.752964, 20.898582, 1913.5],
      [-100.753087, 20.898668, 1913.1],
      [-100.75317, 20.898565, 1912.7],
      [-100.753233, 20.89848, 1912.3],
      [-100.753496, 20.898129, 1910.9],
      [-100.753849, 20.897629, 1910.9],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 4487.8,
      duration: 316.0,
    },
  },
  {
    id: "hospital-joya",
    name: "Hospital Joya",
    position: [20.911144463887737, -100.75978540318],
    routeCoordinates: [
      [-100.759785, 20.910892, 1880.0],
      [-100.759967, 20.91079, 1880.0],
      [-100.760083, 20.910724, 1880.0],
      [-100.759987, 20.91059, 1880.0],
      [-100.760208, 20.910779, 1880.0],
      [-100.760297, 20.910855, 1880.0],
      [-100.76039, 20.910767, 1880.0],
      [-100.7603, 20.910692, 1880.0],
      [-100.760191, 20.910599, 1880.0],
      [-100.759976, 20.910418, 1880.0],
      [-100.758966, 20.909546, 1886.7],
      [-100.758826, 20.909436, 1887.6],
      [-100.758181, 20.908881, 1892.1],
      [-100.757952, 20.908657, 1891.3],
      [-100.757168, 20.907807, 1886.7],
      [-100.756844, 20.907424, 1887.8],
      [-100.756411, 20.907008, 1891.0],
      [-100.756189, 20.906806, 1891.4],
      [-100.755775, 20.906431, 1891.1],
      [-100.755603, 20.906229, 1891.1],
      [-100.755469, 20.906037, 1891.1],
      [-100.755226, 20.905567, 1891.5],
      [-100.755151, 20.905314, 1891.6],
      [-100.755095, 20.905066, 1891.9],
      [-100.755076, 20.904808, 1892.8],
      [-100.755085, 20.904288, 1894.6],
      [-100.75514, 20.903608, 1896.0],
      [-100.755217, 20.903022, 1897.3],
      [-100.755305, 20.902054, 1899.3],
      [-100.755357, 20.901399, 1902.3],
      [-100.755367, 20.901094, 1904.2],
      [-100.755365, 20.900978, 1904.8],
      [-100.755307, 20.900711, 1906.6],
      [-100.755246, 20.900521, 1907.8],
      [-100.755195, 20.900302, 1909.2],
      [-100.75509, 20.900035, 1911.4],
      [-100.754787, 20.899625, 1913.4],
      [-100.754577, 20.899434, 1913.7],
      [-100.753972, 20.898977, 1913.5],
      [-100.753684, 20.898788, 1912.7],
      [-100.753415, 20.898599, 1912.0],
      [-100.75336, 20.89849, 1911.7],
      [-100.753379, 20.898346, 1911.5],
      [-100.753496, 20.898129, 1911.1],
      [-100.753849, 20.897629, 1911.0],
      [-100.753882, 20.89754, 1911.2],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 2912.3,
      duration: 209.8,
    },
  },
  {
    id: "hospital-bilingue",
    name: "Instituto Bilingüe Milenio",
    position: [20.899887269568712, -100.73260750318016],
    routeCoordinates: [
      [-100.733137, 20.900295, 2061.0],
      [-100.731808, 20.901801, 2061.0],
      [-100.731451, 20.90219, 2058.0],
      [-100.731229, 20.902335, 2056.6],
      [-100.731128, 20.902426, 2056.2],
      [-100.730868, 20.902699, 2054.6],
      [-100.730595, 20.902951, 2053.4],
      [-100.730479, 20.903056, 2053.0],
      [-100.730545, 20.903109, 2052.6],
      [-100.730719, 20.903216, 2051.8],
      [-100.730778, 20.903134, 2051.4],
      [-100.731336, 20.902513, 2050.9],
      [-100.731466, 20.902392, 2050.4],
      [-100.73161, 20.902297, 2049.9],
      [-100.731769, 20.902234, 2049.4],
      [-100.732164, 20.902118, 2047.3],
      [-100.732314, 20.902117, 2046.7],
      [-100.732464, 20.902135, 2046.1],
      [-100.733068, 20.902301, 2042.9],
      [-100.733613, 20.902474, 2040.8],
      [-100.734658, 20.902788, 2037.5],
      [-100.735016, 20.902909, 2036.8],
      [-100.735195, 20.903006, 2036.3],
      [-100.735489, 20.903199, 2036.3],
      [-100.735678, 20.903357, 2036.9],
      [-100.735921, 20.903619, 2038.1],
      [-100.736554, 20.904281, 2034.7],
      [-100.736879, 20.904631, 2029.6],
      [-100.737234, 20.904895, 2024.4],
      [-100.737477, 20.905002, 2021.2],
      [-100.737679, 20.905031, 2018.2],
      [-100.737928, 20.905044, 2015.9],
      [-100.738152, 20.905027, 2014.2],
      [-100.738316, 20.904997, 2013.5],
      [-100.738639, 20.904838, 2012.0],
      [-100.739453, 20.90429, 2004.8],
      [-100.739745, 20.904058, 2002.5],
      [-100.739881, 20.903882, 2001.0],
      [-100.739977, 20.903708, 1999.5],
      [-100.740192, 20.90319, 1996.4],
      [-100.740309, 20.903054, 1996.0],
      [-100.74046, 20.902935, 1995.5],
      [-100.740665, 20.902863, 1995.1],
      [-100.741349, 20.902675, 1995.0],
      [-100.741526, 20.902573, 1995.0],
      [-100.74163, 20.90248, 1995.0],
      [-100.741735, 20.902314, 1993.8],
      [-100.741957, 20.901728, 1988.8],
      [-100.742027, 20.9016, 1988.0],
      [-100.742121, 20.901473, 1987.1],
      [-100.742356, 20.901222, 1984.0],
      [-100.742507, 20.901062, 1982.0],
      [-100.742582, 20.900904, 1981.1],
      [-100.742615, 20.900713, 1979.5],
      [-100.742647, 20.900227, 1977.4],
      [-100.742684, 20.900044, 1977.5],
      [-100.74277, 20.899901, 1977.7],
      [-100.742859, 20.899796, 1978.0],
      [-100.743073, 20.899726, 1979.6],
      [-100.74398, 20.899637, 1982.8],
      [-100.744385, 20.899603, 1981.4],
      [-100.744833, 20.899488, 1979.7],
      [-100.745133, 20.899331, 1977.0],
      [-100.74542, 20.899133, 1974.1],
      [-100.745653, 20.898905, 1972.4],
      [-100.745786, 20.898711, 1971.0],
      [-100.746031, 20.898181, 1962.8],
      [-100.7467, 20.896732, 1937.3],
      [-100.746785, 20.896597, 1937.2],
      [-100.746978, 20.896368, 1937.9],
      [-100.747316, 20.89607, 1938.8],
      [-100.747609, 20.89594, 1939.4],
      [-100.747823, 20.895877, 1938.9],
      [-100.748269, 20.895827, 1937.0],
      [-100.748478, 20.89582, 1935.9],
      [-100.748749, 20.895857, 1934.7],
      [-100.748971, 20.895917, 1933.5],
      [-100.749197, 20.896, 1932.2],
      [-100.749495, 20.896158, 1930.1],
      [-100.750358, 20.896742, 1924.0],
      [-100.750462, 20.896858, 1923.5],
      [-100.750539, 20.896914, 1923.0],
      [-100.751552, 20.897626, 1918.4],
      [-100.752032, 20.897951, 1917.3],
      [-100.75234, 20.898161, 1916.3],
      [-100.752782, 20.898449, 1914.3],
      [-100.752964, 20.898582, 1913.5],
      [-100.753087, 20.898668, 1913.1],
      [-100.75317, 20.898565, 1912.7],
      [-100.753233, 20.89848, 1912.3],
      [-100.753496, 20.898129, 1910.9],
      [-100.753849, 20.897629, 1910.9],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 4736.5,
      duration: 347.7,
    },
  },
  {
    id: "centro-educativo",
    name: "Centro Educativo Naciones Unidas",
    position: [20.89314804334463, -100.72742514735805],
    routeCoordinates: [
      [-100.727457, 20.89289, 2085.5],
      [-100.726393, 20.892777, 2083.8],
      [-100.724595, 20.894759, 2077.4],
      [-100.724515, 20.894847, 2077.1],
      [-100.72519, 20.895634, 2077.2],
      [-100.725948, 20.896523, 2077.6],
      [-100.726341, 20.896978, 2076.0],
      [-100.726633, 20.897299, 2074.5],
      [-100.726948, 20.897652, 2073.3],
      [-100.727318, 20.898078, 2072.8],
      [-100.72844, 20.899651, 2071.5],
      [-100.729248, 20.900782, 2066.6],
      [-100.729464, 20.901085, 2064.4],
      [-100.729534, 20.901235, 2063.9],
      [-100.729592, 20.901357, 2063.3],
      [-100.730096, 20.902432, 2057.6],
      [-100.730215, 20.9026, 2056.9],
      [-100.730479, 20.903056, 2055.1],
      [-100.730545, 20.903109, 2054.8],
      [-100.730719, 20.903216, 2054.2],
      [-100.730778, 20.903134, 2054.0],
      [-100.731336, 20.902513, 2050.9],
      [-100.731466, 20.902392, 2050.4],
      [-100.73161, 20.902297, 2049.9],
      [-100.731769, 20.902234, 2049.4],
      [-100.732164, 20.902118, 2047.3],
      [-100.732314, 20.902117, 2046.7],
      [-100.732464, 20.902135, 2046.1],
      [-100.733068, 20.902301, 2042.9],
      [-100.733613, 20.902474, 2040.8],
      [-100.734658, 20.902788, 2037.5],
      [-100.735016, 20.902909, 2036.8],
      [-100.735195, 20.903006, 2036.3],
      [-100.735489, 20.903199, 2036.3],
      [-100.735678, 20.903357, 2036.9],
      [-100.735921, 20.903619, 2038.1],
      [-100.736554, 20.904281, 2034.7],
      [-100.736879, 20.904631, 2029.6],
      [-100.737234, 20.904895, 2024.4],
      [-100.737477, 20.905002, 2021.2],
      [-100.737679, 20.905031, 2018.2],
      [-100.737928, 20.905044, 2015.9],
      [-100.738152, 20.905027, 2014.2],
      [-100.738316, 20.904997, 2013.5],
      [-100.738639, 20.904838, 2012.0],
      [-100.739453, 20.90429, 2004.8],
      [-100.739745, 20.904058, 2002.5],
      [-100.739881, 20.903882, 2001.0],
      [-100.739977, 20.903708, 1999.5],
      [-100.740192, 20.90319, 1996.4],
      [-100.740309, 20.903054, 1996.0],
      [-100.74046, 20.902935, 1995.5],
      [-100.740665, 20.902863, 1995.1],
      [-100.741349, 20.902675, 1995.0],
      [-100.741526, 20.902573, 1995.0],
      [-100.74163, 20.90248, 1995.0],
      [-100.741735, 20.902314, 1993.8],
      [-100.741957, 20.901728, 1988.8],
      [-100.742027, 20.9016, 1988.0],
      [-100.742121, 20.901473, 1987.1],
      [-100.742356, 20.901222, 1984.0],
      [-100.742507, 20.901062, 1982.0],
      [-100.742582, 20.900904, 1981.1],
      [-100.742615, 20.900713, 1979.5],
      [-100.742647, 20.900227, 1977.4],
      [-100.742684, 20.900044, 1977.5],
      [-100.74277, 20.899901, 1977.7],
      [-100.742859, 20.899796, 1978.0],
      [-100.743073, 20.899726, 1979.6],
      [-100.74398, 20.899637, 1982.8],
      [-100.744385, 20.899603, 1981.4],
      [-100.744833, 20.899488, 1979.7],
      [-100.745133, 20.899331, 1977.0],
      [-100.74542, 20.899133, 1974.1],
      [-100.745653, 20.898905, 1972.4],
      [-100.745786, 20.898711, 1971.0],
      [-100.746031, 20.898181, 1962.8],
      [-100.7467, 20.896732, 1937.3],
      [-100.746785, 20.896597, 1937.2],
      [-100.746978, 20.896368, 1937.9],
      [-100.747316, 20.89607, 1938.8],
      [-100.747609, 20.89594, 1939.4],
      [-100.747823, 20.895877, 1938.9],
      [-100.748269, 20.895827, 1937.0],
      [-100.748478, 20.89582, 1935.9],
      [-100.748749, 20.895857, 1934.7],
      [-100.748971, 20.895917, 1933.5],
      [-100.749197, 20.896, 1932.2],
      [-100.749495, 20.896158, 1930.1],
      [-100.750358, 20.896742, 1924.0],
      [-100.750462, 20.896858, 1923.5],
      [-100.750539, 20.896914, 1923.0],
      [-100.751552, 20.897626, 1918.4],
      [-100.752032, 20.897951, 1917.3],
      [-100.75234, 20.898161, 1916.3],
      [-100.752782, 20.898449, 1914.3],
      [-100.752964, 20.898582, 1913.5],
      [-100.753087, 20.898668, 1913.1],
      [-100.75317, 20.898565, 1912.7],
      [-100.753233, 20.89848, 1912.3],
      [-100.753496, 20.898129, 1910.9],
      [-100.753849, 20.897629, 1910.9],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 5843.4,
      duration: 471.0,
    },
  },
  {
    id: "escuela",
    name: "Escuela bilingüe José Vasconcelos",
    position: [20.922239943184465, -100.738670699482],
    routeCoordinates: [
      [-100.738404, 20.92226, 1908.4],
      [-100.738399, 20.922209, 1907.6],
      [-100.738424, 20.922075, 1907.4],
      [-100.738489, 20.921867, 1906.9],
      [-100.73852, 20.921781, 1906.8],
      [-100.738626, 20.921612, 1906.6],
      [-100.738642, 20.921592, 1906.5],
      [-100.738865, 20.921203, 1906.3],
      [-100.738897, 20.92118, 1906.3],
      [-100.739115, 20.921166, 1906.3],
      [-100.739163, 20.920973, 1906.4],
      [-100.739156, 20.920811, 1906.4],
      [-100.739171, 20.920707, 1906.4],
      [-100.739316, 20.920299, 1906.4],
      [-100.739456, 20.919709, 1907.5],
      [-100.740453, 20.919757, 1909.0],
      [-100.740489, 20.919361, 1909.1],
      [-100.740638, 20.918392, 1910.7],
      [-100.740726, 20.917691, 1913.6],
      [-100.740792, 20.916899, 1916.6],
      [-100.740941, 20.916844, 1916.9],
      [-100.740985, 20.916783, 1917.1],
      [-100.741084, 20.916402, 1918.8],
      [-100.741175, 20.915962, 1920.8],
      [-100.741237, 20.91551, 1923.3],
      [-100.741935, 20.915514, 1924.5],
      [-100.741996, 20.914532, 1920.0],
      [-100.742331, 20.914518, 1918.5],
      [-100.742325, 20.914028, 1918.4],
      [-100.742148, 20.914019, 1918.8],
      [-100.742074, 20.912746, 1925.1],
      [-100.742888, 20.912695, 1926.0],
      [-100.743635, 20.912696, 1926.0],
      [-100.744593, 20.912642, 1922.3],
      [-100.74513, 20.91264, 1918.5],
      [-100.746182, 20.912615, 1908.0],
      [-100.746258, 20.912025, 1902.1],
      [-100.746409, 20.910884, 1893.2],
      [-100.746433, 20.910761, 1892.9],
      [-100.746467, 20.910606, 1892.6],
      [-100.746657, 20.910158, 1892.7],
      [-100.746871, 20.909724, 1894.5],
      [-100.747024, 20.909365, 1896.3],
      [-100.747178, 20.909032, 1898.4],
      [-100.747672, 20.907821, 1905.5],
      [-100.747771, 20.907531, 1906.9],
      [-100.747865, 20.907259, 1907.7],
      [-100.748139, 20.906573, 1907.5],
      [-100.748271, 20.906235, 1906.4],
      [-100.748335, 20.906081, 1906.1],
      [-100.748369, 20.905997, 1905.8],
      [-100.748444, 20.905806, 1905.2],
      [-100.748454, 20.905781, 1904.9],
      [-100.748458, 20.905772, 1904.7],
      [-100.748548, 20.905525, 1904.2],
      [-100.748711, 20.905048, 1903.7],
      [-100.748764, 20.904892, 1903.7],
      [-100.748973, 20.90436, 1904.0],
      [-100.749102, 20.904053, 1904.0],
      [-100.74925, 20.903717, 1904.5],
      [-100.749301, 20.903634, 1904.8],
      [-100.749647, 20.903109, 1906.6],
      [-100.749756, 20.902966, 1906.9],
      [-100.750141, 20.902446, 1907.6],
      [-100.750236, 20.902316, 1907.6],
      [-100.750479, 20.901981, 1906.9],
      [-100.750538, 20.9019, 1906.7],
      [-100.750708, 20.901664, 1906.3],
      [-100.750755, 20.901596, 1906.1],
      [-100.750956, 20.901324, 1905.7],
      [-100.751213, 20.901094, 1905.5],
      [-100.751312, 20.900962, 1905.6],
      [-100.751621, 20.900576, 1906.4],
      [-100.751958, 20.900137, 1907.0],
      [-100.752889, 20.898904, 1908.3],
      [-100.753087, 20.898668, 1908.7],
      [-100.75317, 20.898565, 1908.9],
      [-100.753233, 20.89848, 1909.0],
      [-100.753496, 20.898129, 1909.5],
      [-100.753849, 20.897629, 1910.8],
      [-100.753882, 20.89754, 1911.1],
      [-100.754182, 20.89714, 1912.4],
      [-100.755085, 20.89598, 1918.4],
      [-100.755243, 20.895799, 1919.2],
      [-100.755419, 20.895624, 1920.1],
      [-100.755947, 20.895174, 1922.3],
      [-100.756883, 20.894382, 1926.5],
      [-100.757042, 20.894247, 1927.5],
      [-100.757522, 20.893841, 1930.5],
      [-100.757609, 20.893781, 1930.9],
      [-100.757859, 20.893576, 1931.3],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 4671.7,
      duration: 553.2,
    },
  },
  {
    id: "presa",
    name: "Presa Allende",
    position: [20.84915283604159, -100.82602547503342],
    routeCoordinates: [
      [-100.825814, 20.849155, 1852.0],
      [-100.825813, 20.849117, 1852.0],
      [-100.825877, 20.848929, 1852.0],
      [-100.825902, 20.84889, 1852.0],
      [-100.825929, 20.848846, 1852.0],
      [-100.826026, 20.848797, 1852.0],
      [-100.826092, 20.848736, 1852.0],
      [-100.826117, 20.848634, 1852.0],
      [-100.826194, 20.848584, 1852.0],
      [-100.826153, 20.848537, 1852.0],
      [-100.825932, 20.84834, 1851.7],
      [-100.825451, 20.847819, 1850.2],
      [-100.825341, 20.847646, 1849.4],
      [-100.825245, 20.847556, 1848.9],
      [-100.825124, 20.847484, 1848.5],
      [-100.824573, 20.847389, 1846.1],
      [-100.824379, 20.847311, 1845.0],
      [-100.824029, 20.847093, 1843.4],
      [-100.82375, 20.846865, 1842.7],
      [-100.823431, 20.846491, 1842.3],
      [-100.823326, 20.846334, 1842.4],
      [-100.823156, 20.846018, 1842.9],
      [-100.822973, 20.845612, 1844.5],
      [-100.822752, 20.845035, 1848.1],
      [-100.822663, 20.844749, 1850.3],
      [-100.822604, 20.844493, 1851.8],
      [-100.822367, 20.844037, 1855.0],
      [-100.822306, 20.84394, 1855.5],
      [-100.822227, 20.843774, 1856.4],
      [-100.822051, 20.843425, 1859.9],
      [-100.821597, 20.842603, 1869.9],
      [-100.819206, 20.838118, 1898.5],
      [-100.817691, 20.835196, 1925.8],
      [-100.815, 20.831491, 1935.2],
      [-100.814856, 20.831338, 1935.3],
      [-100.814698, 20.831213, 1935.0],
      [-100.814366, 20.831037, 1935.0],
      [-100.81419, 20.830978, 1935.2],
      [-100.813906, 20.830928, 1936.4],
      [-100.813797, 20.830917, 1936.9],
      [-100.813569, 20.830924, 1937.9],
      [-100.808913, 20.831786, 1953.9],
      [-100.805575, 20.832412, 1958.2],
      [-100.80425, 20.83264, 1958.0],
      [-100.797863, 20.833855, 1948.3],
      [-100.797385, 20.833908, 1947.9],
      [-100.797122, 20.833915, 1947.8],
      [-100.796797, 20.83389, 1947.8],
      [-100.795874, 20.833775, 1950.1],
      [-100.795274, 20.833678, 1952.6],
      [-100.795167, 20.834061, 1954.1],
      [-100.795132, 20.834325, 1954.8],
      [-100.795113, 20.834708, 1955.5],
      [-100.795127, 20.834961, 1955.6],
      [-100.795159, 20.835172, 1955.5],
      [-100.795256, 20.835518, 1955.1],
      [-100.795585, 20.836185, 1954.0],
      [-100.795905, 20.836648, 1952.6],
      [-100.796707, 20.837879, 1941.5],
      [-100.796912, 20.838149, 1938.5],
      [-100.796961, 20.83826, 1937.6],
      [-100.797019, 20.838495, 1935.9],
      [-100.797067, 20.838736, 1934.5],
      [-100.797087, 20.83898, 1933.1],
      [-100.797046, 20.840235, 1926.3],
      [-100.796784, 20.841486, 1918.5],
      [-100.796492, 20.842381, 1913.3],
      [-100.796387, 20.842665, 1912.2],
      [-100.796218, 20.843041, 1910.9],
      [-100.796016, 20.843488, 1910.1],
      [-100.791664, 20.853904, 1877.7],
      [-100.791079, 20.855252, 1873.0],
      [-100.791042, 20.855338, 1872.7],
      [-100.790993, 20.855443, 1872.4],
      [-100.790728, 20.856195, 1870.5],
      [-100.789349, 20.859459, 1868.2],
      [-100.787146, 20.864761, 1868.8],
      [-100.784216, 20.87165, 1872.5],
      [-100.783892, 20.872456, 1872.9],
      [-100.782559, 20.875625, 1861.1],
      [-100.782299, 20.876007, 1859.3],
      [-100.782185, 20.87613, 1859.0],
      [-100.781954, 20.876358, 1858.6],
      [-100.781604, 20.876614, 1859.0],
      [-100.781327, 20.876753, 1859.8],
      [-100.780984, 20.87687, 1860.8],
      [-100.780802, 20.876915, 1861.3],
      [-100.780574, 20.87695, 1862.3],
      [-100.779921, 20.876935, 1866.5],
      [-100.778898, 20.876965, 1876.9],
      [-100.778552, 20.877024, 1880.8],
      [-100.778223, 20.877119, 1884.5],
      [-100.778018, 20.877204, 1886.9],
      [-100.777794, 20.877304, 1889.1],
      [-100.774929, 20.87917, 1892.3],
      [-100.774671, 20.879342, 1891.9],
      [-100.774145, 20.87976, 1891.3],
      [-100.772329, 20.881289, 1884.2],
      [-100.770305, 20.882988, 1882.6],
      [-100.769972, 20.883269, 1882.3],
      [-100.769718, 20.883483, 1881.5],
      [-100.766349, 20.88632, 1893.0],
      [-100.763991, 20.888297, 1907.5],
      [-100.76321, 20.888937, 1910.9],
      [-100.763115, 20.889006, 1911.2],
      [-100.76204, 20.889863, 1917.6],
      [-100.760926, 20.890794, 1926.5],
      [-100.758889, 20.892523, 1929.6],
      [-100.758426, 20.892916, 1929.7],
      [-100.757521, 20.893689, 1929.8],
      [-100.757442, 20.893757, 1930.0],
      [-100.757522, 20.893841, 1930.1],
      [-100.757609, 20.893781, 1930.2],
      [-100.757859, 20.893576, 1930.2],
      [-100.758767, 20.892837, 1930.1],
      [-100.759027, 20.892624, 1929.8],
      [-100.75923, 20.89245, 1929.6],
      [-100.759548, 20.892178, 1930.1],
      [-100.760772, 20.891138, 1930.1],
    ],
    summary: {
      distance: 13276.4,
      duration: 780.6,
    },
  },
];
