import React from 'react';
import './SanMiguel.scss'
import { useParallax } from 'react-scroll-parallax';
const SanMiguel = () => {
    const down = useParallax({
        speed: -15  
    });
    const up = useParallax({
        speed: 5
    })
    return ( 
        <div id='sanmiguel' className='contenedor_sanmiguel'>
            <div  className='contenedor_sanmiguel_card'>
                <div className='left'>
                    <div className='card'>
                        <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/san-miguel-de-allende.svg" alt="" />
                        <div className='texto'>
                            <p>San Miguel de Allende fue nombrada en el año de 2021, la mejor ciudad del mundo por la revista Travel+Leisure, es considerado Patrimonio de la humanidad, ofreciendo un gran valor artístico, histórico, gastronómico y cultural. Se ha convertido en el destino favorito del turismo, tanto extranjero como nacional, así como uno de los mejores lugares para vivir.</p>
                        </div>
                        <div className='icono'>
                            <img src="/assets/1.Logos/logo-word-best-awards.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <img ref={down.ref} src="/assets/4.Imagenes/img_vinedos_la_serena.jpg" alt="" />
                    <img ref={up.ref} src="/assets/4.Imagenes/img_plaza-san-miguel_la_serena.jpg" alt="" />
                </div>
            </div>
        </div>
     );
}
 
export default SanMiguel;