import logo from './logo.svg';
import './App.css';
import React,{useState, useEffect, useCallback} from 'react';
import Menu from "./Componentes/Menu/Menu";
import Header from "./Componentes/Header/Header";
import Principal from "./Componentes/Principal/Principal";
import Concepto from "./Componentes/Concepto/Concepto";
import Descipcion from "./Componentes/Descripcion/Descripcion";
import Amenidades from "./Componentes/Amenidades/Amenidades";
import Prototipos from "./Componentes/Prototipos/Prototipos";
import MasterPlan from "./Componentes/MasterPlan/MasterPlan";
import SanMiguel from "./Componentes/SanMiguel/SanMiguel";
import Ubicacion from "./Componentes/Ubicacion/Ubicacion";
import Galeria from "./Componentes/Galeria/Galeria";
import Footer from "./Componentes/Footer/Footer";
import TempPrototipos from "./Componentes/TempPrototipos/TempPrototipos";
import Stiky from './Componentes/stiky/Stiky';
import Brochure from './Componentes/Brochure/Brochure';
import './App.scss'
import { CSSTransition } from "react-transition-group";
import { SwiperSlide } from "swiper/react";
import Slider from './Componentes/Slider/Slider';
import { ParallaxProvider,ParallaxBanner  } from 'react-scroll-parallax';
import 'animate.css';
import WOW from 'wowjs';

function App() {
  const [showModal, setModal] = useState(false)
  const [showBrochure, setBrochure] = useState(false)
  const [movile, setmovil] = useState(false)
  const [windows, setWindow] = useState(window.innerWidth)

    useEffect(()=>{
        new WOW.WOW({
        live: false
        }).init();
    },[]); 
  const changeWindow = useCallback(
    (e)=>{
      const window = e.currentTarget;
      if (window.innerWidth <= 1024) {
        setmovil(true)
        console.log(window.innerWidth,'movil')
        if(window.innerHeight <= 420){
            setmovil(false)
        }
      }else{
        setmovil(false)
      }
      setWindow(window.innerWidth)
    },
    [windows]
  )
  useEffect(()=>{
    setWindow(window.innerWidth)
    if (window.innerWidth <= 1024) {
      setmovil(true)
      console.log(window.innerWidth,'movil')
      if(window.innerHeight <= 420){
          setmovil(false)
      }
    }else{
      setmovil(false)
    }
    window.addEventListener('resize', changeWindow);

  },[changeWindow])

  return (
    <div className="App">
      {
        movile &&
        <CSSTransition
        in={showModal}
        timeout={200}
        classNames="show"
        unmountOnExit
        >
          <TempPrototipos 
            setModal={setModal}
          >
          <div className='contenedor_modal_prototipos'>
            <p className='closed' onClick={()=>{setModal(false)}}>X</p>
            <div className='modal'>
            <Slider nav={true} pag={true}>
            <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/ALDAMA PENT GARDEN_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/ALDAMA FLAT_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/ALDAMA PENT HOUSE_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/GUADIANA PENT GARDEN_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/GUADIANA PENT HOUSE_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/RECREO PENT GARDEN_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/RECREO FLAT_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/RECREO PENT HOUSE_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/STO DOMINGO PENT HOUSE_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/UMARAN PLANTA BAJA_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/UMARAN PLANTA ALTA_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/UMARAN ROOF GARDEN_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/MOVIL/ZACATEROS PENT GARDEN_1-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
            </Slider>
            </div>
          </div>
          </TempPrototipos>
        </CSSTransition>
      } 
      {
        !movile  && 
          <CSSTransition
          in={showModal}
          timeout={200}
          classNames="show"
          unmountOnExit
          >
          <TempPrototipos 
            setModal={setModal}
          >
            <div className='contenedor_modal_prototipos'>
            <p className='closed' onClick={()=>{setModal(false)}}>X</p>
            <div className='modal'>
                <Slider nav={true} pag={true}>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/ALDAMA PENT GARDEN-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/ALDAMA FLAT-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/ALDAMA PENT HOUSE-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/GUADIANA PENT GARDEN-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/GUADIANA PENT HOUSE-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/RECREO PENT GARDEN-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/RECREO FLAT-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/RECREO PENT HOUSE-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/STO DOMINGO PENT HOUSE-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/UMARAN PLANTA BAJA-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/UMARAN PLANTA ALTA-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/UMARAN ROOF GARDEN-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/assets/6. Fichas/ZACATEROS PENT GARDEN-100.jpg" alt="prototipo" />
                        </div>
                    </SwiperSlide>
                </Slider>
            </div>
          </div>
          </TempPrototipos>
        </CSSTransition>
      } 
      <CSSTransition
            in={showModal}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <TempPrototipos 
          setModal={setModal}
        />
      </CSSTransition>
      <CSSTransition
            in={showBrochure}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <Brochure 
          setModal={setBrochure}
        />
      </CSSTransition>
        <Stiky setModal={setBrochure}/>
        <Header setModal={setBrochure} setModalP={setModal}/>
        <ParallaxProvider>
        <Principal/>
        <Concepto setModal={setModal}/>
        <Descipcion setModal={setModal}/>
        <Amenidades setModal={setModal}/>
        <Prototipos setModal={setModal}/>
        <MasterPlan setModal={setModal}/>
        <SanMiguel/>
        <div className="contenedor_img">
            <ParallaxBanner
                layers={[{ image: '/assets/4.Imagenes/img_san_miguel_la_serena.png', speed: -20 }]}
                className="img_parallax"
            />
          {/* <img src="/assets/4.Imagenes/img_san_miguel_la_serena.png" alt="" /> */}
        </div>
        <Ubicacion/>
        <Galeria/>
        </ParallaxProvider>
        <Footer/>
        <Menu setModal={setModal}/>
    </div>
  );
}

export default App;
