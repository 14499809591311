import React from 'react';
import './MasterPlan.scss'
const MasterPlan = (props) => {
    return ( 
        <div id='masterplan' className='contenedor_masterplan'>
            <div className='contenedor_masterplan_titulo'>
                <img className='wow animate__animated animate__fadeInDown' src="/assets/3.Titulos/master-plan.svg" alt="" />
            </div>
            <div className='contenedor_masterplan_plan'>
                <div className='background'><a onClick={()=>{props.setModal(true)}}>PROTOTIPOS</a></div>
                <img src="/assets/4.Imagenes/img-masterplan _la_serena.jpg" alt="" />
            </div>
            <div className='contenedor_masterplan_lista'>
                <div className='contenedor_masterplan_lista_prototipos'>
                    <ul>
                        <li><div></div>RECREO</li>
                        <li><div></div>GUADIANA</li>
                        <li><div></div>ALDAMA</li>
                        <li><div></div>UMARÁN</li>
                        <li><div></div>ZACATEROS/CUNA DE ALLENDE</li>
                        <li><div></div>ZACATEROS/SANTO DOMINGO</li>
                    </ul>
                </div>
                <div className='contenedor_masterplan_lista_amenidades'>
                    <ul>
                        <li className='first-column'><p>1</p>Cancha de padel</li>
                        <li className='first-column'><p>2</p>Cancha de tenis</li>
                        <li className='first-column'><p>3</p>Piscinas</li>
                        <li className='first-column'><p>4</p>Jacuzzi</li>
                        <li className='first-column'><p>5</p>Chapoteadero</li>
                        <li className='first-column'><p>6</p>Andadores</li>
                        <li className='first-column'><p>7</p>Área de juegos</li>
                        <li className='second_column'><p>8</p>Mirador</li>
                        <li className='second_column'><p>9</p>Área de asoleaderos</li>
                        <li className='second_column'><p>10</p>Área de servicios</li>
                        <li className='second_column'><p>11</p>Espacio de uso común</li>
                        <li className='second_column'><p>12</p>Áreas verdes</li>
                        <li className='second_column'><p>13</p>Estacionamientoi</li>
                        <li className='second_column'><p>14</p>Plaza</li>
                        <li className='third-column'><p>15</p>Casa club</li>
                    </ul>
                </div>
            </div>
        </div>
     );
}
 
export default MasterPlan;